import React from "react";
import PropTypes from "prop-types";

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <span className="icon fa-audio-description" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>Anderson Diaz</h1>
        <p>High Touch Investments</p>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Header;
